import axios from 'axios'
import { useRouter } from 'vue-router'

const customAxios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
  withCredentials: true,
})
const router = useRouter()

const requestHandler = (request) => {
  request.headers['X-Requested-With'] = 'XMLHttpRequest'
  return request
}

const responseHandler = (response) => {
  if (!response.data.success && typeof response.data.success !== 'undefined') {
    let isErrorAuth = response.data.errors.some((error) => error.code === '401')
    if (isErrorAuth) {
      let errorMessage = response.data.errors.map((error) => error.message).join('\n')
      return Promise.reject(errorMessage)
    }
    let errorMessage = response.data.errors.map((error) => error.message).join('\n')
    return Promise.reject(errorMessage)
  }
  return response
}

const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('config')
    return Promise.resolve()
  }
  if (error.response.status === 403) {
    return Promise.reject(error)
  }
  if (error.response.status === 404) {
    return Promise.reject(`${error.response.config.url} not found`)
  }
  if (error.response.status === 400) {
    let errorMessage = error.response.data.errors.map((error) => error.message).join('\n')
    return Promise.reject(error.response)
  }
  return Promise.reject(error)
}

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default customAxios
