import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/useUserStore'

const Home = () => import('@/pages/Home.vue')
const Login = () => import('@/pages/Login.vue')
const Category = () => import('@/pages/Category.vue')
const Product = () => import('@/pages/Product.vue')
const NearMe = () => import('@/pages/NearMe.vue')
const ContentPage = () => import('@/pages/ContentPage.vue')
const Account = () => import('@/pages/Account.vue')
const ChangePassword = () => import('@/pages/ChangePassword.vue')
const ForgotPassword = () => import('@/pages/ForgotPassword.vue')
const ResetPassword = () => import('@/pages/ResetPassword.vue')
const ActivateAccount = () => import('@/pages/ActivateAccount.vue')
const EgFuelBarcode = () => import('@/pages/EgFuelBarcode.vue')

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login | PerkMates',
      authenticated: false,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home | PerkMates',
      authenticated: true,
    },
  },
  {
    path: '/category/:category',
    name: 'Category',
    component: Category,
    meta: {
      authenticated: true,
    },
  },
  {
    path: '/category/:category/:product',
    name: 'Product',
    component: Product,
    meta: {
      authenticated: true,
    },
    children: [
      {
        path: ':provider',
        component: Product,
        meta: {
          authenticated: true,
        },
      },
    ],
  },
  {
    path: '/egFuel',
    name: 'Eg Fuel',
    component: EgFuelBarcode,
    meta: {
      title: 'Fuel Barcode | PerkMates',
      authenticated: true,
    },
  },
  {
    path: '/near-me',
    name: 'Near Me',
    component: NearMe,
    meta: {
      title: 'Perks Near Me | PerkMates',
      authenticated: true,
    },
  },
  {
    path: '/page/:page',
    name: 'Content Page',
    component: ContentPage,
    authenticated: false,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account | PerkMates',
      authenticated: true,
    },
  },
  {
    path: '/account/change-password',
    name: 'Change Password',
    component: ChangePassword,
    meta: {
      title: 'Change Password | PerkMates',
      authenticated: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password | PerkMates',
      authenticated: false,
    },
  },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: ResetPassword,
    meta: {
      title: 'Reset Password | PerkMates',
      authenticated: false,
    },
  },
  {
    path: '/activate-account',
    name: 'Activate Account',
    component: ActivateAccount,
    meta: {
      title: 'Activate Account | PerkMates',
      authenticated: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'auto' })
  },
  routes,
})

router.beforeResolve((to, from) => {
  const userStore = useUserStore()
  if (to.meta?.authenticated && !userStore.getIsAuthenticated) {
    window.location.href = '/'
  }
  document.title = to.meta?.title ?? 'PerkMates'
})

export default router
