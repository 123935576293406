<script setup>
import { useUserStore } from '@/store/useUserStore'
import { onBeforeMount } from 'vue'

onBeforeMount(async () => {
  const userStore = useUserStore()
  await userStore.fetchUser()
})
</script>
<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>
