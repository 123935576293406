import { defineStore } from 'pinia'
import customAxios from '@/customAxios'
import router from '@/router'

const STORAGE_KEY = 'config'
export const useUserStore = defineStore('user', {
  state: () => ({
    isAuthenticated: false,
    user: null,
    loadingUser: false,
  }),

  getters: {
    getIsAuthenticated: (state) => {
      return state.isAuthenticated
    },
  },
  actions: {
    async fetchUser() {
      this.loadingUser = true
      const url = `/api/customer/details`
      const cachedConfig = JSON.parse(localStorage.getItem(STORAGE_KEY))
      if (cachedConfig) {
        this.loadingUser = false
        this.isAuthenticated = true
      }
      let response = await customAxios.get(url)

      if (response?.data?.payload?.data) {
        this.user = response?.data?.payload?.data
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.user))
        this.isAuthenticated = true
      }
      if (window.location.pathname === '/' && this.isAuthenticated) {
        window.location.href = '/home'
      }
      this.loadingUser = false
    },
    async logout() {
      localStorage.removeItem(STORAGE_KEY)
      const url = `/api/logout`
      await customAxios.get(url)
      await router.push('/')
    },
  },
})
