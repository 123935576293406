import { createStore } from 'vuex'

import experiencesIcon from '@/assets/images/icon/category/experiences.svg'
import travelIcon from '@/assets/images/icon/category/holidays.svg'
import diningIcon from '@/assets/images/icon/category/dining.svg'
import everydayIcon from '@/assets/images/icon/category/everyday.svg'
import entertainmentIcon from '@/assets/images/icon/category/cinemas.svg'
import businessSavingsIcon from '@/assets/images/icon/category/business-savings.svg'

import experiences from '@/assets/images/category/experiences.jpg'
import travel from '@/assets/images/category/holidays.jpg'
import dining from '@/assets/images/category/dining.jpg'
import everyday from '@/assets/images/category/everyday.jpg'
import entertainment from '@/assets/images/category/entertainment.jpg'
import businessSavings from '@/assets/images/category/business-savings.jpg'

import everydayMarker from '@/assets/images/markers/fuel.png'
import foodAndDiningMarker from '@/assets/images/markers/food-and-dining.png'
import experiencesMarker from '@/assets/images/markers/experiences.png'
import lifestyleMarker from '@/assets/images/markers/lifestyle.png'
import travelMarker from '@/assets/images/markers/travel.png'
import shoppingMarker from '@/assets/images/markers/shopping.png'

export default createStore({
  state: {
    categories: [
      {
        name: 'Experiences',
        urlSegment: 'experiences',
        image: experiences,
        icon: experiencesIcon,
        marker: experiencesMarker,
      },
      {
        name: 'Holidays',
        urlSegment: 'accommodation-and-travel',
        image: travel,
        icon: travelIcon,
        marker: travelMarker,
      },
      {
        name: 'Everyday',
        urlSegment: 'everyday',
        image: everyday,
        icon: everydayIcon,
        marker: everydayMarker,
      },
      {
        name: 'Dining',
        urlSegment: 'dining-play-money-rewards',
        image: dining,
        icon: diningIcon,
        marker: foodAndDiningMarker,
        categoryLink: '/near-me?category=dining',
      },
      {
        name: 'Entertainment',
        urlSegment: 'lifestyle',
        image: entertainment,
        icon: entertainmentIcon,
        marker: lifestyleMarker,
      },
      {
        name: 'Business Savings',
        urlSegment: 'business-savings',
        image: businessSavings,
        icon: businessSavingsIcon,
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
})
