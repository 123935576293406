import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueShowdown from 'vue-showdown'
import VueLazyLoad from 'vue3-lazyload'
import './assets/css/main.scss'
import './assets/scss/base.scss'
import './registerServiceWorker'
import { createPinia } from 'pinia'

const pinia = createPinia()
let app = createApp(App).use(store).use(router).use(VueShowdown).use(VueLazyLoad).use(pinia)

app.mount('#app')
